.table {
    margin-top: 30px;
    margin-bottom: 5px;
    width: 60%;
    border-spacing: 0;
    display: block !important;
    overflow-x: auto;

    .header {
        th {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: $dark-title-text;
            padding: 20px 20px;
            border-top: 1px solid #CACACA;
            text-align: center;
            white-space: nowrap;
            border-right: 1px solid #CACACA;

            &:first-child {
                border-left: 1px solid #CACACA;
            }
        }
    }

    .body {
        border-top: none;

        .lightBackground {
            background-color: #E5E5E5;
        }

        .darkBackground {
            background-color: transparent;
        }

        tr {
            border-right: 1px solid #CACACA;
        }

        tr:last-child {
            border-bottom: 1px solid #CACACA;
        }

        td {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $dark-title-text;
            border-right: 1px solid #CACACA;

            &:last-child {
                border-right: none;
            }

            &:first-child {
                border-left: 1px solid #CACACA;
            }
        }

        .checkboxTableInput {
            height: 20px;
            width: 20px;
        }
    }

    .twoRowsText {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 300px;
    }
}