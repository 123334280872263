.sidebar {
    min-width: 260px;
    min-height: 100vh;
    max-width: 255px;
    background-color: $primary;
    position: fixed;
    overflow-y: auto;
    top: 0;
    bottom: 0;

    img {
        width: 218px;
        height: 56px;
        margin-top: 43px;
        margin-bottom: 30px;
    }

    .nav-link {
        color: $text-menu;
        font-weight: 300;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 60px;

        &.linkActived,
        &.sub-linkActived {
            background-color: rgba(255, 255, 255, 0.2);
            border-left: 3px solid white;
        }

        &.sub-linkActived {
            padding-left: 55px;
        }

        &.sub-linkInactived {
            padding-left: 58px;
        }

        .submenuTitle {
            display: flex;
            align-items: center;
            width: 80%;
            justify-content: space-between;
        }

        svg {
            height: 16px;
            width: 16px;
            margin-right: 24px;
            opacity: 0.5;

            &.activeIcon {
                opacity: 1;
            }
        }
    }
}