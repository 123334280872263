.loginContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;

    img {
        height: 138px;
        width: 543px;
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        min-width: 380px;

        .emailInput {
            margin-bottom: 25px;
        }

        form {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        button {
            margin-top: 45px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            border-radius: 10px;

            &:focus {
                box-shadow: none !important;
            }
        }

        .loadingButton {
            cursor: not-allowed;
            background-color: $primary;
            margin: 45px auto 20px auto;
            border-radius: 10px;
            border: none;
            padding: 10px 25px;
            font-size: 10px;
            height: 41px;
            padding: 5px;
            animation: changeWidthButton 0.5s linear;
        }

        @keyframes changeWidthButton {
            from {
                width: 100%
            }

            to {
                width: 10%
            }
        }

        .spinnerLoadingButton {
            color: white;
        }

        .errorWarning {
            color: #dc3545;
            margin-top: 10px;
        }
    }
}

.passwordResetContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;

    img {
        height: 100px;
        width: 500px;
    }

    .loginForm {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        min-width: 300px;

        form {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        input {
            &:first-of-type {
                margin-bottom: 15px;
            }
        }

        button {
            margin-top: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            border-radius: 10px;
            min-width: 300px;

            &:focus {
                box-shadow: none !important;
            }
        }
    }

    .errorWarning {
        color: #dc3545;
        margin-top: 15px;
        text-align: center;
    }
}