.coursesContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 0px 300px;
    width: calc(100%);

    .headerContainer {
        margin-right: 27px;
        display: flex;
        justify-content: space-between;

        .leftHeaderContainer {
            display: flex;
            align-items: center;

            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                margin-right: 34px;
                white-space: nowrap;
            }

            select {
                background-color: #F1F1F1;
                border: none;
                height: 45px;
                min-width: 200px;
                font-size: 14px;
                font-weight: 500;
                color: $dark-title-text;
            }
        }

        .addItemButton {
            background-color: $primary;
            color: white;
            border-radius: 10px;
            border: none;
            padding: 10px 25px
        }
    }

    .createCourseContainer {
        border-top: 0.6px solid #131F2A;
        margin-top: 20px;
        margin-right: 27px;

        .formContainer {
            max-width: 1000px;
            margin-top: 23px;
        }

        .rowFields {
            display: flex;
            margin-top: 15px;
            gap: 15px;

            select {
                border: 1px solid #131F2A;
            }

            select,
            input,
            .dropzone.dropzone--single {
                width: calc((100% - 30px) / 3);
            }

            .form-select.is-invalid,
            .form-control.is-invalid {
                border-color: #dc3545 !important;
                background-image: none !important;
                padding-right: 0 !important;
            }

            .dropzone__drop-here {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .rc-time-picker {
                &.time-picker {
                    width: calc((100% - 30px) / 3);

                    .rc-time-picker-input {
                        border: 1px solid #131F2A !important;
                        width: 100%;
                        height: 100%;
                        font-size: 1rem;
                        padding: 6px 12px;
                    }
                }

                &.time-picker-error {
                    width: calc((100% - 30px) / 3);

                    @extend .time-picker;

                    .rc-time-picker-input {
                        border-color: #dc3545 !important;
                    }
                }
            }
        }

        .coursePicturePreview {
            height: 166px;

            button {
                border: none;
                position: absolute;
                background-color: transparent;
                padding: 0;
                margin-top: 10px;
                margin-left: 135px;
            }

            img {
                height: 166px;
                width: 159px;
                border-radius: 16px;
            }
        }

        .buttons {
            margin-top: 15px;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
}

.rc-time-picker-panel-narrow {
    max-width: 130px;
}

.rc-time-picker-panel-select {
    font-size: 14px;
    width: 64px;
}

.rc-time-picker-panel-inner {
    font-size: 14px;
}

.advertisementsContainer {
    display: flex;
    flex-direction: column;
    padding: 150px 40px 0px 300px;
    width: calc(100%);

    .header {
        h6 {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            padding-bottom: 20px;
        }

        border-bottom: 0.6px solid #131F2A;
        margin-bottom: 44px;
    }

    .buttons {
        display: flex;
        justify-content: end;

        button {
            width: 164px;
            margin-top: 20px;
            border-radius: 10px;
        }
    }
}