.usersContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 0px 300px;
    width: calc(100%);

    .title {
        padding-left: 17px;
        padding-right: 27px;
        display: flex;
        justify-content: space-between;

        .addItemButton {
            background-color: $primary;
            color: white;
            border-radius: 10px;
            border: none;
            padding: 10px 25px
        }

        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
        }

        .autoSearchInput {
            background: rgba(33, 33, 33, 0.08);
            border-radius: 5px;
            border: none;
            padding: 9px 0px 9px 15px;
            color: #212529;
            min-width: 400px;

            &::placeholder {
                color: #444444;
            }

            &::before {
                border: none !important;
            }

            &::after {
                border: none !important;
            }

            &.Mui-focused {
                border: 1px solid #444444;
            }

            svg {
                height: 24px;
                width: 24px;
                margin-right: 15px;
            }
        }
    }
}

.userContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 50px 0px 300px;
    width: calc(100%);
    overflow-x: auto;
    white-space: nowrap;

    .headerContainer {
        display: flex;
        margin-right: 65px;

        .title {
            font-weight: 600;
            font-size: 18px;
            color: #7C7C7C;
            margin-bottom: 18px;
        }

        .leftHeader {
            display: flex;
            align-items: center;
            margin-right: 90px;

            .photo {
                width: 140px;
                height: 140px;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 10px;
            }

            .personalData {
                .name {
                    font-weight: 400;
                    font-size: 24px;
                    color: #2B2B2B;
                    margin-bottom: 19px;
                }

                h5 {
                    font-weight: 400;
                    font-size: 16px;
                    color: #2B2B2B;
                }

                .categories {
                    display: flex;
                    margin-top: 7px;

                    h5 {
                        margin-left: 5px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                button {
                    margin-top: 15px;
                    background-color: $primary;
                    color: white;
                    border-radius: 5px;
                    border: none;
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .rightHeader {
            border: 1px solid #D2D2D2;
            border-radius: 13px;
            font-weight: 500;

            .rowTable {
                border-bottom: 1px solid #D2D2D2;
                display: flex;
                padding: 6px 55px 6px 55px;

                h6 {
                    font-size: 16px;
                    color: #2B2B2B;

                    &:first-child {
                        min-width: 250px;
                    }
                }

                &:first-child {
                    padding: 25px 0px 25px 55px;
                    border-bottom: none;
                }

                &:last-child {
                    padding-bottom: 25px;
                    padding-top: 15px;
                    border-bottom: none;
                }
            }
        }
    }
}
.tabsContent{
    .MuiTabs-indicator {
        background-color: #812E31;
    }
    .Mui-selected , .MuiTab-textColorSecondary{
        color: #30353A !important;
        text-transform: none;
        font-size: 16px;
    }

}