.dashboardContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 0px 300px;
    width: calc(100%);

    .cardContainer {
        display: flex;
        background-color: #E6E6E6;
        border-radius: 10px;
        min-width: 240px;

        .iconContainer {
            padding: 15px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            .icon {
                height: 35px;
                width: 35px;
                color: #fff;
            }
        }

        .contentContainer {
            padding: 14px 10px;
            width: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            h6 {
                font-weight: 500;
                font-size: 20px;
                white-space: nowrap;

                &:last-child {
                    font-weight: 400;
                    font-size: 16px
                }
            }
        }
    }

    .datePickerContainer {
        display: flex;

        input {
            padding: 9px 14px !important;
            max-width: 130px !important;
            padding-right: 0 !important;
        }

        button {
            padding-left: 0px;
        }

        legend {
            display: none;
        }
    }

    .graphContainer {
        margin-top: 30px;
        background-color: #F7F7F7;
        padding: 20px 30px 20px 0px;
        width: 100%;

        h6 {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            padding: 15px 0px 25px 40px;
        }
    }
}