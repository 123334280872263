.subscriptionsContainer {
    display: flex;
    flex-direction: column;
    padding: 150px 40px 0px 300px;
    width: calc(100%);

    .headerContainer {
        margin-right: 27px;
        display: flex;
        justify-content: space-between;

        .leftHeaderContainer {
            display: flex;
            align-items: center;

            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                margin-right: 34px;
                white-space: nowrap;
            }

            select {
                background-color: #F1F1F1;
                border: none;
                height: 45px;
                min-width: 200px;
                font-size: 14px;
                font-weight: 500;
                color: $dark-title-text;
            }
        }

        .addItemButton {
            background-color: $primary;
            color: white;
            border-radius: 10px;
            border: none;
            padding: 10px 25px
        }
    }
}