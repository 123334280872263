.assessmentsContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 0px 300px;
    width: calc(100%);

    .headerContainer {
        padding-right: 27px;
        display: flex;
        justify-content: space-between;

        .leftHeaderContainer {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                margin-right: 34px;
            }

            select {
                background-color: #F1F1F1;
                border: none;
                height: 45px;
                min-width: 200px;
                font-size: 14px;
                font-weight: 500;
                color: $dark-title-text;
            }
        }

        .addItemButton {
            background-color: $primary;
            color: white;
            border-radius: 5px;
            border: none;
            padding: 10px 15px;
            display: flex;
            align-items: center;
        }
    }

    .selectorsContainer {
        margin-top: 15px;
        display: flex;

        select {
            background-color: #F1F1F1;
            border: none;
            height: 45px;
            min-width: 200px;
            font-size: 14px;
            font-weight: 500;
            color: $dark-title-text;
            margin-right: 15px;
            max-width: 200px;
        }
    }

    .questionsContainer {
        border: 1px solid #7C7C7C;
        border-radius: 10px;
        padding: 10px 25px 20px 25px;
        margin-right: 40px;
        max-width: 1050px;
        margin-top: 30px;

        .dragIcon {
            cursor: move;
            width: fit-content;
            margin: auto;
            margin-bottom: 20px;
        }

        .questionHeader {
            margin-bottom: 25px;
            display: flex;

            select {
                background-color: #131F2A;
                border: none;
                height: 45px;
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                max-width: 162px;
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");

                &:first-of-type {
                    margin-right: 10px;
                }

                .selectOption {
                    background-color: #F1F1F1;
                    color: #2B2B2B;
                }
            }
        }

        .dismissButton {
            display: flex;
            justify-content: flex-end;

            button {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                border-radius: 10px;
                padding: 10px 15px;
                border: none;
                color: #7C7C7C;
            }
        }
    }

    .form-select.is-invalid,
    .form-control.is-invalid {
        border: 1px solid #dc3545 !important;
        background-image: none !important;
        padding-right: 0 !important;
    }

    .addQuestion {
        display: flex;
        justify-content: flex-end;
        max-width: 1050px;
        margin: 35px 40px 35px 0px;

        button {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            border-radius: 10px;
            padding: 10px 20px;
            border: none;
            color: #fff;
            background-color: #131F2A;
            min-width: 120px;

            &:first-child {
                margin-right: 15px;
                background-color: #fff;
                color: #131F2A;
                border: 1px solid #131F2A;
            }
        }
    }

    .responseOptionContainer {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .dragIcon {
            cursor: move;
            margin: 0px;
        }

        .indicatorOption {
            margin-left: 5px;
            margin-right: 10px;
            color: #7C7C7C;
        }

        .deleteElement {
            margin-left: 10px;

            svg {
                color: #000;
                cursor: pointer;
            }
        }
    }

    .addOption {
        display: flex;
        align-items: center;

        .dragIcon {
            margin: 0px;
            cursor: default;

            svg {
                color: transparent;
            }
        }

        .checkIndicator {
            margin-left: 5px;
            margin-right: 10px;
            color: #7C7C7C;
        }

        h6 {
            font-weight: 400;
            font-size: 16px;
            text-decoration-line: underline;
            color: #7C7C7C;
            cursor: pointer;
        }
    }

    .assessmentsCoursesTable {
        .body tr td {
            &:last-child {
                display: none;
            }
        }
    }

    .datePickerContainer {
        display: flex;
        margin-left: 20px;

        input {
            padding: 9px 14px !important;
            max-width: 110px !important;
            padding-right: 0 !important;
        }

        button {
            padding-left: 0px;
        }

        legend {
            display: none;
        }

        .MuiTextField-root:last-of-type {
            margin-left: 10px;
        }
    }
}