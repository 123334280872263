.categoriesContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 0px 0px 300px;
    width: calc(100%);

    .headerContainer {
        padding-right: 27px;
        display: flex;
        justify-content: space-between;

        .leftHeaderContainer {
            display: flex;
            align-items: center;

            h4 {
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                margin-right: 34px;
            }

            select {
                background-color: #F1F1F1;
                border: none;
                height: 45px;
                min-width: 200px;
                font-size: 14px;
                font-weight: 500;
                color: $dark-title-text;
            }
        }

        .addItemButton {
            background-color: $primary;
            color: white;
            border-radius: 10px;
            border: none;
            padding: 10px 25px
        }
    }
}

.deleteElementModal {
    max-width: 500px;

    .containButton {
        display: flex;
        justify-content: flex-end;
    }

    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button {
            padding: 0;
        }
    }

    .modal-body {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .btn-outline-danger:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #dc3545;
    }

    .datePickerContainer {
        display: flex;
        margin-left: 20px;

        input {
            padding: 9px 14px !important;
            max-width: 110px !important;
            padding-right: 0 !important;
        }

        button {
            padding-left: 0px;
        }

        legend {
            display: none;
        }

        .MuiTextField-root:last-of-type {
            margin-left: 10px;
        }
    }
}

.createCategoryModal {
    max-width: 750px;

    .containButton {
        display: flex;
        justify-content: flex-end;
        margin: 15px;
    }

    .modal-body {
        padding: 0px 45px 45px !important;

        .closeButton {
            width: 27px;
            height: 27px;
        }

        input,
        textarea {
            margin-bottom: 18px;
            border-color: $primary;
        }

        .form-control.is-invalid {
            border-color: #dc3545 !important;
        }

        .inputError {
            color: #dc3545;
            font-size: 14px;
            font-weight: 400;
            margin: -10px 0 18px 0px;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 50px;

            button {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                border-radius: 10px;
                padding: 10px 25px;
                border: none;
            }

            .secondarybutton {
                background-color: $text-menu;
                color: $primary;
                margin-right: 25px;
            }

            .principalbutton {
                background-color: $primary;
                color: white;
            }
        }
    }

    .btn-secondary:focus {
        box-shadow: none !important;
    }

    .form-select.is-invalid,
    .form-control.is-invalid {
        border-color: #dc3545 !important;
        padding-right: 0 !important;
    }
}

.datePickerStyles {
    input {
        padding: 9px 14px !important;
        padding-right: 0 !important;
        margin-bottom: 0 !important;
    }

    button {
        padding-left: 0px;
    }

    legend {
        display: none;
    }

    .MuiTextField-root:last-of-type {
        margin-left: 0px;
    }
}
.inputGroupRight{
    height: 100%;
    background-color: #131F2A;
    color: white;
}