.termsAndConditionsContainer {
    display: flex;
    flex-direction: column;
    padding: 150px 40px 0px 300px;
    width: calc(100%);

    .header {
        h6 {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            padding-bottom: 20px;
        }

        border-bottom: 0.6px solid #131F2A;
        margin-bottom: 44px;
    }

    .editorContainer {

        .sun-editor .se-resizing-bar .se-navigation {
            display: none !important;
        }

        .updateButton {
            background: #131F2A;
            border-radius: 10px;
            color: white;
            font-weight: 600;
            font-size: 18px;
            padding: 10px 20px;
            margin-top: 20px;

            &:disabled {
                opacity: 0.6;
            }
        }
    }
}