.notFound {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 200px;
    padding-top: 150px;

    img {
        height: 200px;
        width: 200px;
    }

    h3 {
        margin-top: 10px;
        color: #42444c
    }
}